import React, { useState } from "react";

import { useSnackbar } from "../Snackbar";
import Row from "./Row";
import { copyCSVToClipboard } from "./util";
import * as UI from "./ui";

const NOOP = () => {};
const DEFAULT_COLUMNS = ["label", "total", "count", "average"];

const BarTable = ({ series, selectedIndex, setSelectedIndex = NOOP, columns = DEFAULT_COLUMNS }) => {
  const { addMessage } = useSnackbar();
  const [ hovering, setHovering ] = useState(false);

  const handleMouseEnter = () => setHovering(true);
  const handleMouseLeave = () => {
    setHovering(false);
    setSelectedIndex(null);
  };

  const handleTouchStart = (index) => () => {
    setHovering(selectedIndex === null);
    setSelectedIndex(index === selectedIndex ? null : index);
  };

  const handleDoubleClick = e => {
    if(document.selection) {
      document.selection.empty();
    } else {
      window.getSelection().removeAllRanges();
    }
    copyCSVToClipboard({ series, columns });
    addMessage({ type: "success", message: `${series.length} rows copied to clipboard as CSV` });
  }

  const max = Math.max(...series.map(item => item.total));
  const maxCount = Math.max(...series.map(item => item.count));
  const enableScroll = !hovering;

  return (
    <UI.Table onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onDoubleClick={handleDoubleClick} $columns={columns}>
      {series.map((item, index) => (
        <Row
          key={index}
          item={item}
          max={max}
          maxCount={maxCount}
          selected={selectedIndex === index}
          columns={columns}
          onMouseEnter={() => setSelectedIndex(index)}
          onTouchStart={handleTouchStart(index)}
          enableScroll={enableScroll}
          $anySelection={selectedIndex !== null && selectedIndex !== undefined}
        />
      ))}
    </UI.Table>
  );
}

export default BarTable;
