import styled from "styled-components";

export const Layout = styled.div`
  height: 100%;
  overflow-y: auto;
`;

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const DonationLevelPresetList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: -6px -6px 12px;
`;

export const DonationLevelPresetListItem = styled.li`
  padding: 6px;
  width: 50%;
`;

const itemColor = ({ isSelected }) => isSelected ? '#00C19D' : 'rgba(255, 255, 255, 0.8)';
const fontColor = ({ isSelected }) => isSelected ? '#ffffff' : '#666666';

export const DonationLevelPreset = styled.div`
  display: flex;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 12px;
  background: ${itemColor};
  color: ${fontColor};
  transition: all 100ms linear;
  flex-wrap: wrap;
  user-select: none;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Price = styled.div`
  padding: 0 12px;
  font-weight: bold;
  font-size: 1.25em;
`;

export const RewardTickets = styled.div`
  margin-left: auto;
  font-size: 1.125em;
  @media screen and (max-width: 768px) {
    font-size: 1em;
    min-width: 100%;
    padding: 0 12px;
  }
`;

export const SelectionCountHint = styled.div`
  margin-bottom: 24px;
  display: flex;
  font-size: 0.875em;
  align-items: center;
`;

const iconColor = ({ validCount }) => validCount ? '#77aa16' : '#cccccc';

export const HintIcon = styled.div`
  color: ${iconColor};
  margin-right: 12px;
  font-size: 1.25em;
`;

const messageColor = ({ validCount }) => validCount ? '#333333' : '#666666';

export const HintMessage = styled.div`
  color: ${messageColor};
`;

export const StepLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

export const StepContent = styled.div`
  flex: 1;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (max-width: 1024px) {
    max-width: unset;
    min-width: 100%;
  }
`;

export const Instructions = styled.div`
  max-width: 400px;
  padding: 0 12px;

  @media screen and (max-width: 1024px) {
    max-width: unset;
    min-width: 100%;
  }

  h2 {
    margin-bottom: 12px;
    font-size: 1.125em;
    font-weight: bold;
  }

  p {
    margin-bottom: 12px;
    line-height: 1.25em;
  }

  strong {
    font-weight: 700;
  }
`;

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 48px;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 60px;
    align-items: center;
  }
`;

export const RequestApproval = styled.div`
`;

export const RequestApprovalHeading = styled.h3`
  margin-bottom: 6px;
`;

export const Delete = styled.div`
`;

export const Confirmation = styled.div`
  max-width: 528px;
  padding: 24px;
  border-radius: 6px;
  background: #EDF6FB;
`;

export const DeleteHeading = styled.h3`
  margin-bottom: 6px;
`;

export const Description = styled.div`
  font-size: 0.875em;
  margin-bottom: 12px;
`;

export const PostalCodeContainer = styled.div`
  width: 120px;
`;

export const SimpleStepInstructions = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Header = styled.header`
  font-size: 1.5em;
  margin-bottom: 12px;
`;

export const AdvancedSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 12px;
  gap: 12px;
`;

export const Table = styled.table`
  text-align: left;
  width: 100%;
  color: rgba(0,0,0,0.8);
  font-family: monospace;

  th, tr {
    padding: 2px;
  } 
  
  th {
    font-weight: bold;
  }
`;
