import React from "react";
import { evolve } from "ramda";

import { default as LibraryForm } from "../forms/Form";
import useField from "../../lib/useField";
import Button from "../design/Button";
import TextField from "../forms/TextField";
import DateField from "../forms/DateField";
import BonusPercentageSelectField from "../forms/BonusPercentageSelectField";
import DonationLevels from "../DonationLevels";

import { combineValidations, exclusionFrom, formatOf, lengthOf, numericality } from "../../lib/validations";

const DEFAULT_VALIDATIONS = {
  name: formatOf({ pattern: /^[A-Za-z0-9_-]+$/, message: "This field must only contain letters, numbers, hyphens, or underscores."  }),
  bonusPercent: numericality,
  notes: lengthOf({maximum: 1024}),
};

const Preview = ({ sweepstakesId }) => {
  const { value } = useField("bonusPercent");
  return <DonationLevels sweepstakesId={sweepstakesId} promoCode={{bonusPercent: value}} />;
};

const Form = ({sweepstakesId, initialInputObject, promoCodes = [], onSubmit, inUse, zone, legacyValue, loading, guarded}) => {
  const existingPromoCodeNames = promoCodes.map(promoCode => promoCode.name).filter(name => name !== initialInputObject.name);
  const validations = evolve({
    name: validation => combineValidations([validation, exclusionFrom(existingPromoCodeNames, "A Promo Code with this name already exists, please choose another.")]),
  }, DEFAULT_VALIDATIONS);

  return (
    <LibraryForm initialInputObject={initialInputObject} onSubmit={onSubmit} validations={validations}>
      <TextField id="name" label="Promo Code" disabled={inUse || guarded} autoFocus />
      <BonusPercentageSelectField
        id="bonusPercent"
        label="Promo Entry Percentage"
        inUse={inUse}
        legacyValue={legacyValue}
        allowBlank
        disabled={guarded}
      />
      <DateField
        id="expirationDate"
        label="Expiration Date"
        zone={zone}
        disabled={guarded}
      />
      <TextField id="notes" label="Notes" disabled={guarded} />
      <Button type="submit" disabled={loading || guarded}>Save Promo Code</Button>
      <Preview sweepstakesId={sweepstakesId} />
    </LibraryForm>
  );
};

export default Form;
