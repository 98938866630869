import { any, complement, pipe, path, prop, props, isEmpty } from "ramda";

import ConnectPaymentAccount from "./ConnectPaymentAccount";
import PrizeForm from "./PrizeForm";
import SweepstakesDetailsForm from "./SweepstakesDetailsForm";
import DonationLevelsForm from "./DonationLevelsForm";
import SweepstakesTextForm from "./SweepstakesTextForm";
import DrawingDetailsForm from "./DrawingDetailsForm";
import MailInAddressForm from "./MailInAddressForm";
import NonprofitContactInfoForm from "./NonprofitContactInfoForm";
import SlideEditor from "./SlideEditor";
import BrandingForm from "./BrandingForm";
import ProfileForm from "./ProfileForm";
import SponsorEditor from "./SponsorEditor";
import AnalyticsIntegrationForm from "./AnalyticsIntegrationForm";
import AnnouncementForm from "./AnnouncementForm";
import NewsletterSignupForm from "./NewsletterSignupForm";
import PrivacyPolicyForm from "./PrivacyPolicyForm";
import { isPresent } from "../../../util/presence";

const isNotEmpty = complement(isEmpty);

export const REQUIRED_STEPS = [
  {
    id: "payment-account",
    name: "Payment Account",
    isComplete: path(['sweepstakes', 'checklist', 'paymentAccount']),
    description: "Connect your Stripe account to receive donations.",
    isLocked: path(['sweepstakes', 'checklist' ,'paymentAccount']),
    Component: ConnectPaymentAccount,
  },
  {
    id: "prize",
    name: "Prize",
    isComplete: path(['sweepstakes', 'checklist', 'prize']),
    description: "Set the name of your Prize",
    isLocked: path(['sweepstakes','approved']),
    Component: PrizeForm,
  },
  {
    id: "details",
    name: "Sweepstakes Details",
    isComplete: path(['sweepstakes', 'checklist', 'sweepstakesDetails']),
    description: "Fill in some simple details about your TapKat sweepstakes including minimum amount to raise to award your prize.",
    Component: SweepstakesDetailsForm,
  },
  {
    id: "donation-levels",
    name: "Donation Amounts",
    isComplete: path(['sweepstakes', 'checklist', 'donationLevels']),
    isLocked: path(['sweepstakes','approved']),
    description: "Specify the dollar amounts your donors can choose from to purchase entries and enter the sweepstakes.",
    Component: DonationLevelsForm
  },
  {
    id: "slides",
    name: "Slides",
    isComplete: path(['sweepstakes', 'checklist', 'slides']),
    description: "Upload images of your prize and create captions.",
    Component: SlideEditor,
  },
  {
    id: "copy",
    name: "Sweepstakes Text",
    isComplete: path(['sweepstakes', 'checklist', 'copy']),
    description: "Write 2 - 3 paragraphs of text about your sweepstakes prize followed by a couple of sentences about your nonprofit. Add prize specs and other details in the Prize Details.",
    Component: SweepstakesTextForm,
  },
  {
    id: "drawing-details",
    name: "Schedule and Location",
    isComplete: path(['sweepstakes', 'checklist', 'schedule']),
    description: "Set the start and end dates for your TapKat sweepstakes and fill in a location for drawing your winner.",
    Component: DrawingDetailsForm,
  },
  {
    id: "branding",
    name: "Nonprofit Branding",
    isComplete: path(['sweepstakes', 'checklist', 'nonprofitBranding']),
    description: "Customize the header on your sweepstakes site.",
    Component: BrandingForm,
  },
  {
    id: "about-nonprofit",
    name: "About Your Nonprofit",
    isComplete: path(['sweepstakes', 'checklist', 'aboutNonprofit']),
    description: "Fill in your Nonprofit's mission statement and upload a marketing image.",
    Component: ProfileForm,
  },
  {
    id: "mail-in-address",
    name: "Mailing Address for Donations",
    isComplete: path(['sweepstakes', 'checklist', 'mailInAddress']),
    description: "Fill in the address at which you will receive mail-in donations.",
    Component: MailInAddressForm,
  },
  {
    id: "nonprofit-contact",
    name: "Nonprofit Contact Info",
    isComplete: path(['sweepstakes', 'checklist', 'nonprofitContact']),
    description: "Fill in the address where your Nonprofit is located.",
    Component: NonprofitContactInfoForm,
  },
];

export const OPTIONAL_STEPS = [
  {
    id: "sponsors",
    name: "Partners and Sponsors",
    isComplete: pipe(path(['sweepstakes', 'sponsors']), isNotEmpty),
    description: "Upload logo graphics and website links to recognize the partners and sponsors who are contributing to or helping out with your sweepstakes.",
    Component: SponsorEditor,
    optional: true,
  },
  {
    id: "announcement",
    name: "Promotional Banner",
    isComplete: pipe(path(['sweepstakes', 'announcement']), isPresent),
    description: "Add a promotional banner to your site to highlight a current promo code or flash contest offer.",
    Component: AnnouncementForm,
    optional: true,
  },
  {
    id: "newsletter-signup",
    name: "Newsletter Signup",
    isComplete: pipe(path(['sweepstakes', 'nonprofit', 'profile', 'newsletterWebsite']), isPresent),
    description: "Add a newsletter signup link to your sweepstakes page.",
    Component: NewsletterSignupForm,
    optional: true,
  },
  {
    id: "privacy-policy",
    name: "Privacy Policy",
    isComplete: pipe(path(['sweepstakes', 'nonprofit', 'profile', 'privacyPolicyWebsite']), isPresent),
    description: "Add a link to your privacy policy to your sweepstakes page.",
    Component: PrivacyPolicyForm,
    optional: true,
  },
  {
    id: "analytics",
    name: "Analytics Integration",
    isComplete: pipe(prop('sweepstakes'), props(['googleAnalyticsPropertyId', 'googleAdsConversionId']), any(isPresent)),
    description: "Setup Google Analytics or Google Ads to track your sweepstakes.",
    Component: AnalyticsIntegrationForm,
    isLocked: path(['sweepstakes','googleContainerId']),
    optional: true,
  },
];
