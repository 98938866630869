import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { isNil, path } from "ramda";

import AccessControlledButton from "../../../lib/AccessControlledButton";
import TypeToConfirm from "../../../lib/TypeToConfirm";
import Menu from "../../../lib/Menu";
import { Context as SnackbarContext } from "../../../lib/Snackbar";
import { deleteSweepstakesMutation } from "./gql";
import * as UI from "./ui";

const Delete = ({ sweepstakes, disabled }) => {
  const navigate = useNavigate();
  const { addMessage } = useContext(SnackbarContext);
  const [deleteSweepstakes, { loading }] = useMutation(deleteSweepstakesMutation, {
    update: (cache, results) => {
      const id = path(['data', 'deleteSweepstakes', 'sweepstakesId'], results);

      if (isNil(id)) {
        return
      }

      const cacheId = cache.identify({ id, __typename: "Sweepstakes" });
      cache.evict(cacheId);
      cache.gc();
    }
  });

  if (sweepstakes.approved) {
    return null;
  }

  const handleDelete = () => {
    deleteSweepstakes({
      variables: {
        id: sweepstakes.id,
      },
    }).then(() => {
      navigate(`/${sweepstakes.nonprofit.id}`);
    }).catch((error) => {
      addMessage({ message: "There was an issue deleting this sweepstakes. Contact support if the issue persists.", type: "error" });
    });
  };

  return (
    <UI.Delete>
      <UI.DeleteHeading>Delete Sweepstakes</UI.DeleteHeading>
      <Menu label={<AccessControlledButton intent="destructive" size="small" icon={faTrash} resource="sweepstakes" permission="D">Delete Sweepstakes</AccessControlledButton>}>
        <UI.Confirmation>
          <UI.DeleteHeading>Are you sure?</UI.DeleteHeading>
          <UI.Description>
            This action is irreversible. You will lose all data associated with this sweepstakes.
          </UI.Description>
          <TypeToConfirm password={sweepstakes.prize.name} label="Delete Sweepstakes" onConfirm={handleDelete} disabled={loading} />
        </UI.Confirmation>
      </Menu>
    </UI.Delete>
  );
};

export default Delete;
