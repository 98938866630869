import { gql } from "@apollo/client";

export const sweepstakesEntriesQuery = gql`
  query GetSweepstakesEntries($id: SweepstakesSlug!, $search: String, $filter: EntrySearchFilterEnum) {
    sweepstakes(id: $id) {
      id
      timezone
      entries(search: $search, filter: $filter) {
        id
        createdAt
        status
        amount
        paymentInfo
        ticketCount
        notes
        firstName
        lastName
        phoneNumber
        email
        paymentProcessorManagementUrl

        address {
          id
          line1
          line2
          city
          state
          postalCode
          country
        }

        ticketRanges {
          min
          max
        }

        promoCode {
          id
          name
        }
      }
    }
  }
`;
