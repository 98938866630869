import React from "react";
import { useQuery } from "@apollo/client";
import { pathOr } from "ramda";

import MasterDetail from "../../lib/MasterDetail";
import { useSchedulePeriod } from "../../lib/schedule";
import EntryExports from "../EntryExports";
import { sweepstakesEntriesQuery } from "./gql";
import Item from "./Item";
import Detail from "./Detail";
import Empty from "./Empty";
import NoSelection from "./NoSelection";

const DEFAULT_FILTER = "PAID";
const SEARCH_FILTER = "ALL";

const EntryList = ({ sweepstakesId }) => {
  const period = useSchedulePeriod();
  const newItemEnabled = ["open", "closing", "grace", "closed"].includes(period);

  const { loading, data, refetch } = useQuery(sweepstakesEntriesQuery, {
    variables: {
      id: sweepstakesId,
      filter: DEFAULT_FILTER,
    }
  });

  const onSearch = (args = {}) => {
    const { value } = args;

    refetch({
      id: sweepstakesId,
      search: value,
      filter: value ? SEARCH_FILTER : DEFAULT_FILTER,
    });
  };

  const entries = pathOr([], ["sweepstakes","entries"], data);
  const sweepstakes = pathOr([], ["sweepstakes"], data);
  const search = {
    auto: true,
    placeholder: "John Smith, john.smith@test.com, 555-555-5555…",
    onSubmit: onSearch,
  };

  const newItem = {
    label: "Add Donation",
    resource: "entries",
    permission: "C",
    disabled: !newItemEnabled,
  }

  const footerMenu = <EntryExports sweepstakesId={sweepstakesId} />;

  return (
    <MasterDetail
      items={entries}
      search={search}
      loading={loading}
      ListItemComponent={Item}
      DetailComponent={Detail}
      NoSelectionComponent={NoSelection}
      EmptyComponent={Empty}
      newItem={newItem}
      footerMenu={footerMenu}
      sweepstakes={sweepstakes}
    />
  );
}

export default EntryList;
