import React, { useCallback, useState, useContext } from "react";
import { v4 as uuid } from "uuid";

import Message from "./Message";
import * as UI from "./ui";

export const Context = React.createContext();
export const useSnackbar = () => useContext(Context);

const Snackbar = ({ children }) => {
  const [ messages, setMessages ] = useState([]);

  const removeMessage = useCallback(id => {
    setMessages(messages => {
      return messages.filter(message => message.id !== id)
    });
  }, [setMessages]);

  const addMessage = useCallback(message => {
    const id = uuid();
    setMessages(messages => [...messages, {...message, id}]);

    setTimeout(() => removeMessage(id), 4250);
  }, [removeMessage]);

  const value = { addMessage };

  return (
    <React.Fragment>
      <UI.MessageList>
        { messages.map(message => <Message {...message} key={message.id} /> )}
      </UI.MessageList>
      <Context.Provider value={value}>
        {children}
      </Context.Provider>
    </React.Fragment>
  );
}

export default Snackbar;
