import styled from "styled-components";

export const Layout = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Instructions = styled.p`
  font-family: inherit;
  font-size: 0.875em;
  font-weight: bold;
  color: #333;
`;

export const Form = styled.form`
  display: flex;
  gap: 6px;
`;

export const Input = styled.input`
  font-family: inherit;
  font-size: 0.875em;
  outline: none;
  border: none;
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 3px;
  flex: 1;
`;

export const Button = styled.button`
  font-family: inherit;
  font-size: 0.875em;
  outline: none;
  border: none;
  padding: 6px 12px;
  border-radius: 3px;
  background-color: #db3a34;
  color: #fff;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
  }
`;
