import { gql } from "@apollo/client";

export const sweepstakesOverviewQuery = gql`
  query SweepstakesOverviewQuery($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id
      beginsAt
      endsAt
      approved
      threshold
      totalDonations


      slides {
        id
        image {
          publicId
          cloudName
          version
        }
      }

      checklist {
        id
        sweepstakesDetails
        donationLevels
        slides
        copy
        schedule
        mailInAddress
        paymentAccount
        nonprofitBranding
        aboutNonprofit
        nonprofitContact
      }

      prize {
        id
        win {
          id
          confirmedAt
        }
      }

    }
  }
`;

export const insightsQuery = gql`
  query InsightsQuery($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id
      insights {
        id
        all: performanceTimeline(resolution: ALL) {
          data {
            total
            count
            average
          }
        }
      }

    }
  }
`;

export const activeFlashContestQuery = gql`
  query ActiveFlashContestQuery($sweepstakesId: SweepstakesSlug!) {
    sweepstakes(id: $sweepstakesId) {
      id
      activeFlashContest {
        id
        name
        image {
          publicId
          cloudName
          version
        }
        insights {
          all: performanceTimeline(resolution: ALL) {
            data {
              total
              count
              average
            }
          }
        }
      }
    }
  }
`;

export const activeThankYouCampaignQuery = gql`
  query ActiveThankYouCampaignQuery($sweepstakesId: SweepstakesSlug!) {
    sweepstakes(id: $sweepstakesId) {
      id
      activeThankYouCampaign {
        id
        name
        smallImageUrl
        insights {
          all: performanceTimeline(resolution: ALL) {
            data {
              total
              count
              average
            }
          }
        }
      }
    }
  }
`;

export const blogPostsQuery = gql`
  query BlogPostsQuery($tag: String!) {
    blogPosts(tag: $tag) {
      id
      title
      url
      excerpt
    }
  }
`;

export const marketingScheduleQuery = gql`
  query MarketingScheduleQuery($sweepstakesId: SweepstakesSlug!) {
    sweepstakes(id: $sweepstakesId) {
      id
      calendar {
        id
        events {
          date
          summary
          description
          actionRequired
        }
      }
    }
  }
`;

export const winnerQuery = gql`
  query WinnerQuery($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id
      prize {
        id
        winningEntry {
          id
          firstName
          lastName
          address {
            city
            state
          }
        }
        winningTicket {
          id
          number
        }
      }
    }
  }
`;
export const retroQuery = gql`
  query SweepstakesRetrospectiveReportQuery($id: SweepstakesSlug!) {
    sweepstakesRetrospectiveReport(id: $id) {
      id
    }
  }
`;
