import React from "react";
import { map, pipe, path, sortBy } from "ramda";

import { formatAsDollars } from "../../util/money";
import BarTable from "../../lib/BarTable";
import * as UI from "./ui";

const buildChartData = pipe(
  sortBy(path(['donationLevel', 'price'])),
  map(datum => ({
    label: formatAsDollars(datum.donationLevel.price),
    total: datum.total,
    count: datum.count,
  }))
);

const DonationLevelInsightCard = ({ data }) => {
  const series = buildChartData(data);

  return (
    <UI.Layout>
      <UI.Header>
        <UI.Heading>Donation Metrics</UI.Heading>
      </UI.Header>
      <UI.Body>
        <UI.TableBackground>
          { series.length > 0 ? (
            <BarTable series={series} columns={["label", "total", "count"]} />
          ) : (
            <UI.Empty>No donation metrics available yet.</UI.Empty>
          )}
        </UI.TableBackground>
      </UI.Body>
    </UI.Layout>
  );
};

export default DonationLevelInsightCard;
