import React from "react";
import { useQuery } from "@apollo/client";
import { pathOr } from "ramda";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import StatSummary from "../design/StatSummary";
import Link from "../design/Link";
import { activeThankYouCampaignQuery } from "./gql";
import * as UI from "./ui";

const ThankYouCampaignOverview = ({ sweepstakes: { id }}) => {
  const { data, loading, error } = useQuery(activeThankYouCampaignQuery, {
    variables: { sweepstakesId: id }
  });

  if(loading || error) {
    return null;
  }

  const thankYouCampaign = pathOr(null, ["sweepstakes", "activeThankYouCampaign"], data);

  if(!thankYouCampaign) {
    return null;
  }

  const insightData = pathOr({}, ["insights", "all", "data", 0], thankYouCampaign );

  return (
    <UI.Section style={{ gridArea: "thank-you-campaign" }}>
      <UI.SectionHeading>Active Thank You Campaign: {thankYouCampaign.name}</UI.SectionHeading>
      <UI.Subsection>
        <UI.ThankYouCampaignImage src={thankYouCampaign.smallImageUrl} />
        <UI.SubsectionContent>
          <StatSummary {...insightData} />
          <Link to="./thank-you-campaigns" icon={faArrowRight} size="small">Manage Thank You Campaigns</Link>
        </UI.SubsectionContent>
      </UI.Subsection>
    </UI.Section>
  );
}

export default ThankYouCampaignOverview;
