import React from "react";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import AccessControlledLink from "../../../lib/AccessControlledLink";
import { isBlank } from "../../../util/presence";

const LINK_TEXT = {
  SUCCESSFUL: "Refund Entry on Stripe",
  DISPUTED:   "Manage Dispute on Stripe",
};

const StripeManagementLink = ({ entry }) => {
  const managementUrl = entry.paymentProcessorManagementUrl;
  const hideLink = isBlank(managementUrl) || !Object.keys(LINK_TEXT).includes(entry.status);

  if (hideLink) {
    return null;
  }

  return (
    <AccessControlledLink href={managementUrl} size="small" resource="entries" permission="U" icon={faExternalLinkAlt}>
      {LINK_TEXT[entry.status]}
    </AccessControlledLink>
  );
};

export default StripeManagementLink;
