import React, { useState } from "react";

import * as UI from "./ui";

const TypeToConfirm = ({ password, label, onConfirm, disabled }) => {
  const [value, setValue] = useState("");

  return (
    <UI.Layout>
      <UI.Instructions>
        Type "<strong>{password}</strong>" to confirm.
      </UI.Instructions>
      <UI.Form>
        <UI.Input
          value={value}
          placeholder={password}
          onChange={(e) => setValue(e.target.value)}
          disabled={disabled}
        />
        <UI.Button
          onClick={onConfirm}
          disabled={disabled || value !== password}
        >{label}</UI.Button>
      </UI.Form>
    </UI.Layout>
  );
};

export default TypeToConfirm;
