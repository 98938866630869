import { formatAsDollars } from "../../util/money";

export const getHeader = column => {
  switch(column) {
    case "label":
      return "Label";
    case "total":
      return "Total";
    case "count":
      return "Count";
    case "average":
      return "Average";
    case "percentage":
      return "Percentage";
    default:
      return column;
  }
};

export const getValue = (item, column) => {
  switch(column) {
    case "label":
      return item.label;
    case "total":
      return formatAsDollars(item.total);
    case "count":
      return item.count;
    case "average":
      return formatAsDollars(item.average);
    case "percentage":
      return `${(item.percentage * 100).toFixed(2)}%`;
    default:
      return item[column];
  }
}

export const copyCSVToClipboard = ({ series, columns }) => {
  const headers = columns.map(column => getHeader(column));
  const rows = series.map(item => columns.map(column => `"${getValue(item, column)}"`).join(","));
  const csv = [headers, ...rows].join("\n");
  navigator.clipboard.writeText(csv);
}
